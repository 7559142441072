
  import Navbar from "../components/Navbar";
  import CustomFooter from "../components/Footer";
  import BtnPrimary from "../components/BtnPrimary";
  import Backtop from '../components/backtop';

  export default {
    name: 'Default',
    components: {
      Navbar,
      CustomFooter,
      BtnPrimary,
      Backtop
    },
    data() {
      return {
        show_rule_cookie: false,
        showCollapse: false,
        visibleLangBurger: false,
        modalLogin: false,
        form_login: {
          login: null,
          password: null
        },
        modalRegister: false,
        modalRecovery: false,
        form_recovery: {
          email: null,
          phone: null,
        },
      }
    },
    methods: {
      setCookiesForm() {
        this.show_rule_cookie = false
        this.$cookies.set('set_rule_cookie', true, {
          path: '/',
          maxAge: 60 * 60 * 24 * 7
        })
      },
      openAccount() {
        if (this.$store.getters['account/getPropByKey']('isLogin')) {
          this.$router.push(this.localeLocation(`/account/${this.$store.getters['account/getPropByKey']('id')}`));
        } else {
          this.modalLogin = true;
        }
      },
      openMenu() {
        let mobMenu = this.$el.querySelector('.mobile-menu');
        if (mobMenu.style.display !== 'block') {
          this.$el.style.overflowY = 'hidden';
          mobMenu.style.display = 'block';
          // mobMenu.querySelector('.select-lang').style.display = 'none';
          mobMenu.querySelector('.menu').style.display = 'flex';
        } else {
          this.$el.style.overflowY = 'unset';
          mobMenu.style.display = 'none';
        }
        // mobMenu.querySelector('.select-currency').style.display = 'none';
        mobMenu.querySelector('.select-info-passanger').style.display = 'none';
        this.showCollapse = !this.showCollapse;
      },
      switchCurrency(value) {
        this.$srv.setAgregator(value)
        this.$router.push(this.localeLocation('/'))
      },
      switchCurrencyMenu(value) {
        this.$srv.setAgregator(value)
        this.openMenu();
        this.$router.push(this.localeLocation('/'))
      },
      async SubmitLogin() {

        if (!this.form_login.login) this.$notify.error('Введите Ваш логин')
        if (!this.form_login.password) this.$notify.error('Введите ваш пароль')

        if (this.form_login.login && this.form_login.password) {
          let resp = await this.$store.dispatch('account/login', {
            login: this.form_login.login,
            password: this.form_login.password
          })
          if (resp.error) {
            this.$notify.error(resp.message)
          } else {
            this.modalLogin = false;
            await this.$router.push(this.localeLocation(`/account/${this.$store.getters["account/getPropByKey"]('id')}`));
          }
        }
      },
      openRegModal() {
        this.modalLogin = false;
        this.modalRegister = true;
        this.modalRecovery = false;
      },
      openRecoveryModal() {
        this.modalLogin = false;
        this.modalRegister = false;
        this.modalRecovery = true;
      },
      async SubmitRecovery() {
        if (!this.form_recovery.email) {
          this.$notify.error('Введите Ваш Email')
        } else if (!this.form_recovery.email) {
          this.$notify.error('Введите Ваш номер телефона')
        } else {
          await this.$axios
            .post("api/v1/account/recovery", this.form_recovery)
            .then((resp) => {
              if (!resp.data.error) {
                this.$notify.success(resp.data.message)
                this.modalRecovery = false
              } else {
                this.$notify.error(resp.data.message)
              }
            })
        }
      },
    },
    mounted() {
      let ob = this
      setTimeout(function () {
        ob.show_rule_cookie = !ob.$cookies.get('set_rule_cookie');
      }, 5000)

      let mobMenu = this.$el.querySelector('.mobile-menu');
      //Слушатели для того что бы закрыть меня, после нажатия
      mobMenu.querySelector('.menu').querySelectorAll('li').forEach(itemNav => {
        if (!itemNav.classList.contains('not-link')) {
          itemNav.addEventListener('click', () => {
            this.openMenu();
          });
        }
      });
      // mobMenu.querySelector('.select-currency').querySelectorAll('li').forEach(itemNav => {
      //   if (!itemNav.classList.contains('not-link')) {
      //     itemNav.addEventListener('click', () => {
      //       this.openMenu();
      //     });
      //   }
      // });
      mobMenu.querySelector('.select-info-passanger').querySelectorAll('li').forEach(itemNav => {
        if (!itemNav.classList.contains('not-link')) {
          itemNav.addEventListener('click', () => {
            this.openMenu();
          });
        }
      });

      //Слушатель что бы открыть ввыбор языка
      // mobMenu.querySelector('.language').addEventListener('click', () => {
      //   mobMenu.querySelector('.select-lang').style.display = 'flex';
      //   mobMenu.querySelector('.menu').style.display = 'none';
      // });
      //Слушатель для выбора валюты
      mobMenu.querySelector('.currency-menu').addEventListener('click', () => {
        mobMenu.querySelector('.select-info-passanger').style.display = 'block';
        // mobMenu.querySelector('.select-currency').style.display = 'none';
        mobMenu.querySelector('.menu').style.display = 'none';
      });
      //Слушатель для выбора валюты
      // mobMenu.querySelector('.info-passanger-menu').addEventListener('click', () => {
      //   mobMenu.querySelector('.select-currency').style.display = 'block';
      //   mobMenu.querySelector('.select-info-passanger').style.display = 'none';
      //   mobMenu.querySelector('.menu').style.display = 'none';
      // });
      //Слушатель для шага назад в меню
      mobMenu.querySelectorAll('.icon-back').forEach(item => {
        item.addEventListener('click', () => {
          //mobMenu.querySelector('.select-lang').style.display = 'none';
          // mobMenu.querySelector('.select-currency').style.display = 'none';
          mobMenu.querySelector('.menu').style.display = 'flex';
        });
      })

    },

  }
