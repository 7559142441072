
  import BtnPrimary from "./BtnPrimary";
  import BtnSupport from './BtnSupport';
  import BtnLogin from "./BtnLogin";

  export default {
    name: "Navbar",
    props: ['showCollapse'],
    data() {
      return {
        form_login: {
          login: null,
          password: null
        },
        visiblePassanger: false,
        visibleContact: false,
        visibleLang: false,
        visibleLogin: false,
        visibleCurrency: false,
        visibleCurrencyBurger: false,
        modalLogin: false,


        interval_id: 0,
      }
    },
    methods: {

      logout() {
        this.visibleLogin = false
        this.$router.push({path: `/`});
        this.$store.commit('account/logOut');
      },
      openAccount() {
        this.$emit('open-account');
      },

      switchCurrency(value) {
        this.visibleCurrency = !this.visibleCurrency
        this.$emit('switch-currency', value);
      },
      openMenu() {
        this.$emit('open-menu');
      }
    },
    components: {
      BtnPrimary, BtnLogin, BtnSupport
    }
  };
