
  export default {
    name: "backtop",
    mounted() {
      let elem = this.$el;
      window.addEventListener('scroll', function () {
        let topPosition = window.pageYOffset;
        if (topPosition > 250) {
          elem.classList.add('show')
        } else {
          if (elem.classList.contains('show')) {
            elem.classList.remove(('show'));
          }
        }
      });
    },
    methods: {
      goTop() {
        window.scrollTo(0, 0)
      }
    }
  }
